import { defineComponent } from 'vue';
import Datepicker from '@/core/components/ui/Datepicker.vue';
import { checkDateIsBeforeNowValidator } from '@/core/validators';
import BusinessTypeFormComponent from '@/modules/account-details/components/business-detail/BusinessTypeFormComponent.vue';
export default defineComponent({
    name: 'limited-company-component',
    components: {
        BusinessTypeFormComponent,
        Datepicker,
    },
    props: {
        businessDetails: Object,
        businessType: String,
        disabled: Boolean,
    },
    computed: {
        businessDetailEntity() {
            return this.businessDetails;
        }
    },
    data() {
        return {
            rules: {
                type: [
                    { required: true, message: 'Select a business type', trigger: 'blur' },
                ],
                companyName: [
                    { required: true, message: 'Enter a valid Company name', trigger: 'blur' },
                    { max: 255, message: 'Please enter a maximum of 255 characters.', trigger: 'blur' },
                ],
                companyNumber: [
                    { required: true, message: 'Enter a valid Company number', trigger: 'blur' },
                    { max: 255, message: 'Please enter a maximum of 255 characters.', trigger: 'blur' },
                ],
                incorporationDate: [
                    { required: true, validator: checkDateIsBeforeNowValidator, trigger: 'change' },
                ],
                vat: [
                    { required: true, message: 'VAT is required', trigger: 'change' },
                ],
            },
        };
    },
    methods: {
        onDateChange(date) {
            this.businessDetailEntity.incorporationDate = date;
        },
        triggerSubmit() {
            this.$refs.businessTypeForm.submitForm();
        },
        onSubmitForm() {
            const data = this.businessDetails;
            const limitedCompany = {
                type: 'limited_company',
                companyName: data.companyName,
                businessName: '',
                nationalInsuranceNumber: data.nationalInsuranceNumber,
                taxReferenceNumber: data.taxReferenceNumber,
                companyNumber: data.companyNumber,
                incorporationDate: data.incorporationDate,
                vat: data.vat,
            };
            this.$emit('submit', limitedCompany);
        }
    }
});
