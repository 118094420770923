import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import UserVerificationStatus from '@/core/components/account/UserVerificationStatus.vue';
import CardWrapper from '@/core/components/ui/CardWrapper.vue';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import BusinessDetailsFormComponent from '@/modules/account-details/components/BusinessDetailFormComponent.vue';
import { USERS_STORE } from '../../../store/modules/users';
export default defineComponent({
    name: 'business-detail-page',
    components: {
        CardWrapper,
        UserVerificationStatus,
        BusinessDetailsFormComponent,
    },
    data() {
        return {
            submittingForm: false,
            UserVerificationStatusEnum,
            businessDetails: {
                type: '',
                companyName: '',
                companyNumber: '',
                nationalInsuranceNumber: '',
                taxReferenceNumber: '',
                incorporationDate: '',
                vat: false,
            },
            businessDetailsUserVerificationStatus: UserVerificationStatusEnum.NOT_ADDED,
        };
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user']),
        disabled() {
            return this.user.userVerificationStatus === UserVerificationStatusEnum.PENDING_VERIFICATION;
        }
    },
    created() {
        if (this.user.userBusinessDetails) {
            this.getBusinessDetails({ userId: this.user.id, userBusinessDetailsId: this.user.userBusinessDetails.id })
                .then((response) => {
                this.businessDetails = response;
                this.businessDetailsUserVerificationStatus = response.userVerificationStatus;
            })
                .finally(() => {
                if (!this.businessDetails.type) {
                    this.businessDetails.type = 'limited_company';
                }
            });
        }
        else {
            this.businessDetails.type = 'limited_company';
        }
    },
    methods: {
        ...mapActions(USERS_STORE, [
            'getBusinessDetails',
            'saveBusinessDetails',
            'getAccountStatuses',
            'getUser',
            'saveUserState'
        ]),
        submit(formData) {
            this.submittingForm = true;
            this.saveBusinessDetails({ userId: this.user.id, formData })
                .then(async () => {
                this.$notify.success({
                    message: 'Business Details successfully saved.'
                });
                await this.getAccountStatuses();
                await this.saveUserState(await this.getUser(this.user.id));
                this.$router.push({ name: 'account/identification-check' });
            })
                .catch((e) => {
                this.$notify.error({
                    message: e || 'Error submitting business details.'
                });
            })
                .finally(() => {
                this.submittingForm = false;
            });
        }
    }
});
