import { defineComponent } from 'vue';
export default defineComponent({
    name: 'business-type-form-component',
    props: {
        businessDetails: Object,
        submitting: Boolean,
        rules: Object,
        submit: Function,
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            hasData: this.businessDetails?.id,
            currentType: this.businessDetails?.type,
            types: {
                limited_company: 'Limited Company',
                soletrader: 'Soletrader'
            }
        };
    },
    computed: {
        businessDetailEntity() {
            return this.businessDetails;
        },
        type() {
            return this.types[this.currentType];
        },
    },
    methods: {
        submitForm() {
            this.$refs.businessDetailsForm.validate((valid) => {
                if (valid && this.submit) {
                    this.submit(this.businessDetails);
                }
            });
        },
        onDateChange(date) {
            this.businessDetailEntity.incorporationDate = date;
        },
        onChangeBusinessType(type) {
            if (type === 'limited_company') {
                this.$emit('on-toggle-type', 'soletrader');
            }
            else {
                this.$emit('on-toggle-type', 'limited_company');
            }
        }
    }
});
