import { defineComponent } from 'vue';
import BusinessTypeFormComponent from '@/modules/account-details/components/business-detail/BusinessTypeFormComponent.vue';
export default defineComponent({
    name: 'sole-trader-component',
    components: {
        BusinessTypeFormComponent,
    },
    props: {
        businessDetails: Object,
        submitting: Boolean,
        submit: Function,
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        businessDetailEntity() {
            return this.businessDetails;
        }
    },
    data() {
        return {
            formRules: {
                type: [
                    { required: true, message: 'Select a business type', trigger: 'blur' },
                ],
                nationalInsuranceNumber: [
                    { required: true, message: 'Enter a valid National Insurance number', trigger: 'blur' },
                ],
                taxReferenceNumber: [
                    { required: true, message: 'Enter a valid Tax Reference number', trigger: 'blur' },
                ],
                vat: [
                    { required: true, message: 'VAT is required', trigger: 'change' },
                ],
            }
        };
    },
    methods: {
        triggerSubmit() {
            this.$refs.businessTypeForm.submitForm();
        },
        onSubmitForm(data) {
            const soletrader = {
                type: 'soletrader',
                companyName: '',
                businessName: data.businessName,
                nationalInsuranceNumber: data.nationalInsuranceNumber,
                taxReferenceNumber: data.taxReferenceNumber,
                companyNumber: '',
                incorporationDate: '',
                vat: data.vat,
            };
            this.$emit('submit', soletrader);
        }
    }
});
