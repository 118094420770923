import { defineComponent } from 'vue';
import { BusinessDetailTypeEnum, UserVerificationStatusEnum } from '@/core/packages/shared-library';
import LimitedCompanyComponent from '@/modules/account-details/components/business-detail/LimitedCompanyComponent.vue';
import SoleTraderComponent from '@/modules/account-details/components/business-detail/SoleTraderComponent.vue';
export default defineComponent({
    name: 'business-detail-form-component',
    components: {
        SoleTraderComponent,
        LimitedCompanyComponent,
    },
    props: {
        businessDetails: {
            type: Object,
            required: true
        },
        submitting: Boolean,
        disabled: Boolean,
    },
    data() {
        return {
            businessInfo: {},
            selectedType: '',
            UserVerificationStatusEnum
        };
    },
    watch: {
        businessDetails(business) {
            this.selectedType = business.type;
            this.businessInfo = { ...business };
        }
    },
    created() {
        this.selectedType = this.businessDetails.type;
        this.businessInfo = { ...this.businessDetails };
    },
    methods: {
        onChangeType(type) {
            this.selectedType = type;
        },
        submitForm() {
            if (this.selectedType === BusinessDetailTypeEnum.LIMITED_COMPANY) {
                this.$refs.limitedCompany.triggerSubmit();
            }
            else {
                this.$refs.soletrader.triggerSubmit();
            }
        }
    }
});
